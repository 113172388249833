import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>Shark Tutorial</Title>
                <Text>
                    Et proposem una tutoria amb el nostre psicòleg especialista l'endemà del dia de
                    la prova oficial de BIODATA, CV y test de competències.
                    <br />
                    <br />
                    <strong>Diumenge 18 de 10.00 a 13.00 h a través de ZOOM</strong>
                    <br />
                    <br />
                    Una tutoria exclusiva per 100 persones
                    <br />
                    <br />
                    La dinàmica de la sessió serà la següent:
                    <br />
                    <br />
                    Cada participant escriurà 2 preguntes que prèviament les passarà a través d'un
                    grup de WhatsApp.
                    <br />
                    <br />
                    El dia de la tutoria el psicòleg us donarà resposta a tots.
                    <br />
                    <br />
                    Es tracta d'aprendre del psicòleg, dels companys i de nosaltres mateixos.
                    <br />
                    <br />
                    Aprendreu a parlar en públic i interactuar amb el nostre psicòleg
                    <br />
                    <br />
                    La tutoria Shark quedarà gravada fins al 30 de juny
                    <br />
                    <br />
                    Places limitades!
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
